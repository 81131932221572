import React from "react";
import { Link } from "react-router-dom";

import "./Topbar.css";

export default function Topbar() {

    return (
        <div id="topbar">
            <Link id="title" to="/">
                <h3 id="site-name">Eglantin Jani</h3>
            </Link>
        </div>
    );
};
