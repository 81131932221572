// App.js
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// Stile CSS
import './App.css';

// Pagine
import Home from './home/Home';

// Componenti
import Topbar from './topbar/Topbar';

// Default
export default function App() {

  // ScrollToTop
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Topbar />
    </>
  );
}
