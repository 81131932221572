import React from 'react';
import "./Home.css"

export default function Home() {

    return (
        <div id="home-page">

            <div id="welcome-container">
                <p id="welcome-text" >Ciao, sono un creatore di siti web appassionato e professionale. Mi piace realizzare siti web belli, funzionali e personalizzati per i miei clienti. Se stai cercando un sito web che rispecchi la tua personalità, il tuo brand o la tua attività, sei nel posto giusto. Scrivimi e raccontami cosa hai in mente, non vedo l'ora di lavorare con te!</p>
            </div>

            <h1>Offerte</h1>
            <div className="info-section">

                <div className="card">
                    <div className="card-plan">
                        <h2 className="card-title">Basic</h2>
                        <h2 className="card-price">€100</h2>
                        <p className="card-description">1 pagina</p>
                        <p className="card-description">Consegna in 3 giorni</p>
                    </div>
                    <div className="card-contacts">
                        <p>Per maggiori informazioni</p>
                        <a aria-label="WhatsApp" href="https://wa.me/393383621798">
                            <div className="whatsapp-button-container">
                                <img className="whatsapp-button" alt="WhatsApp" src="/WhatsApp.png" />
                            </div>
                        </a>
                    </div>
                </div>

                <div className="card">
                    <div className="card-plan">
                        <h2 className="card-title">Medium</h2>
                        <h2 className="card-price">€300</h2>
                        <p className="card-description">4 pagine</p>
                        <p className="card-description">Consegna in 10 giorni</p>
                    </div>
                    <div className="card-contacts">
                        <p>Per maggiori informazioni</p>
                        <a aria-label="WhatsApp" href="https://wa.me/393383621798">
                            <div className="whatsapp-button-container">
                                <img className="whatsapp-button" alt="WhatsApp" src="/WhatsApp.png" />
                            </div>
                        </a>
                    </div>
                </div>

                <div className="card">
                    <div className="card-plan">
                        <h2 className="card-title">Pro</h2>
                        <h2 className="card-price">€500</h2>
                        <p className="card-description">8 pagine</p>
                        <p className="card-description">Consegna in 2 settimane</p>
                    </div>
                    <div className="card-contacts">
                        <p>Per maggiori informazioni</p>
                        <a aria-label="WhatsApp" href="https://wa.me/393383621798">
                            <div className="whatsapp-button-container">
                                <img className="whatsapp-button" alt="WhatsApp" src="/WhatsApp.png" />
                            </div>
                        </a>
                    </div>
                </div>

                <div className="card">
                    <div className="card-plan">
                        <h2 className="card-title">Business</h2>
                        <h2 className="card-price">€1000</h2>
                        <p className="card-description">20 pagine</p>
                        <p className="card-description">Consegna in un mese</p>
                    </div>
                    <div className="card-contacts">
                        <p>Per maggiori informazioni</p>
                        <a aria-label="WhatsApp" href="https://wa.me/393383621798">
                            <div className="whatsapp-button-container">
                                <img className="whatsapp-button" alt="WhatsApp" src="/WhatsApp.png" />
                            </div>
                        </a>
                    </div>
                </div>

            </div>

            <h1>Sfoglia siti web creati</h1>
            <div className="info-section">

                <div className="card">
                    <a href="https://xhulianhoxha.pages.dev">
                        <button className="redirect-button">Xhulian Hoxha</button></a>
                </div>

            </div>

            <h1>Contatti</h1>
            <div className="info-section">

                <div className="card" id="card-contacts">
                    <div>
                        <p>
                            <span class="material-symbols-outlined">
                                email
                            </span>
                        </p>
                        <a href="mailto:eglantinjani@gmail.com">
                            <button className="redirect-button">eglantinjani@gmail.com</button></a>
                    </div>
                    <div>
                        <p>
                            <span class="material-symbols-outlined">
                                phone
                            </span>
                        </p>
                        <a href="tel:+393401347387">
                            <button className="redirect-button">+393383621798</button>
                        </a>
                    </div>
                    <div>
                        <p>oppure</p>
                        <a aria-label="WhatsApp" href="https://wa.me/393383621798">
                            <div className="whatsapp-button-container">
                                <img className="whatsapp-button" alt="WhatsApp" src="/WhatsApp.png" />
                            </div>
                        </a>
                    </div>
                </div>

                <div className="card">
                    <p>
                        <span class="material-symbols-outlined">
                            location_on
                        </span>
                    </p>
                    <p>70022</p>
                    <p>Altamura</p>
                    <p>Italia</p>
                </div>

            </div>
        </div >
    );

};